import { Injectable, OnDestroy, PLATFORM_ID, LOCALE_ID, inject, ExperimentalPendingTasks } from '@angular/core';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { combineLatest, Observable, forkJoin, ReplaySubject, firstValueFrom, EMPTY } from 'rxjs';
import { distinctUntilChanged, mergeMap, map, tap, share, catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Navigation as NavigationResponse, NavigationItem as NavigationItemResponse, NavigationListItemSection } from '../types/navigation';
import { ContentRepositoryService } from './content-repository.service';
import { GLOBAL_REGION } from './settings-constants';
import { MappSettings } from './mapp.service';
import { LanguageUrls } from '../types/page';
import { LinkSection } from '../types/link';
import { FooterSection } from '../components/footer/footer.component';
import { AspectRatio, getLogger, PlainLink, RESPONSE } from '@knorr-bremse-portals/ngx-components';
import { imageToPicture, Picture } from '../components/plain-image/plain-image.component';
import { NavigationItem, NavigationLevel } from '@knorr-bremse-portals/ngx-components/components/header';
import { Loggers } from '../../express.tokens';

export type CountryLanguages = { [country: string]: string[] };
export type CountryLanguageUrls = { [country: string]: LanguageUrls };
interface GlobalSettings {
  favicon: string;
  faviconType: string;
  countrySettingUrls: CountryLanguageUrls;
  startPageCountryLanguageCombination: CountryLanguages;
  availableLanguages: string[];
  fallbackLanguage: string;
  fallbackCountry?: string;
  overrideHaupiaTarget: string;
  showGlobalSearch: boolean;
  oneTrustDomainScript: string;
  mappConfig: MappSettings;
  localPartLabel: LanguageUrls;
}

interface BasicCountrySettings {
  customerPortal?: LinkSection;
  titleSuffix: string;
  siteName: string;
  cookieMoreLink: string;
  privacyPolicyLink: string;
  logo: string;
  homeUrl: string;
  navigation: NavigationResponse;
  footer: FooterSection;
  customThemeUrl?: string;
}

export enum DivisionTitleColor {
  Blue  = 'blue',
  Gray = 'gray',
}

interface CountrySettingsWithDivisionTitle extends BasicCountrySettings {
  divisionTitle: string;
  divisionTitleColor: DivisionTitleColor;
}

type AsyncNavigationLevelResponse = [
  label: string,
  href: string,
  children?: AsyncNavigationLevelResponse,
][];

type NavigationCard = {
  picture: Picture;
  heading: string;
  content: string;
  link?: PlainLink;
}

type CountrySettings = BasicCountrySettings | CountrySettingsWithDivisionTitle;

@Injectable({
  providedIn: 'root',
})
export class SettingsService implements OnDestroy {

  private http = inject(HttpClient);
  private contentRepositoryService = inject(ContentRepositoryService);
  private document = inject(DOCUMENT);
  private response = inject(RESPONSE, { optional: true });
  private platformId = inject(PLATFORM_ID);

  private overrideHaupiaTargetSubject = new ReplaySubject<string>(1);
  public readonly overrideHaupiaTarget = this.overrideHaupiaTargetSubject.asObservable();

  private showGlobalSearchSubject = new ReplaySubject<boolean>(1);
  public readonly showGlobalSearch = this.showGlobalSearchSubject.asObservable();

  private oneTrustDomainScriptSubject = new ReplaySubject<string>(1);
  public readonly oneTrustDomainScript = this.oneTrustDomainScriptSubject.asObservable();

  private mappSettingsSubject = new ReplaySubject<MappSettings>(1);
  public readonly mappSettingData = this.mappSettingsSubject.asObservable();

  private localPartLabelsSubject = new ReplaySubject<LanguageUrls>(1);
  public readonly localPartLabels = this.localPartLabelsSubject.asObservable();

  private availableLanguagesSubject = new ReplaySubject<string[]>(1);
  public readonly availableLanguages = this.availableLanguagesSubject.asObservable();

  private countrySettingUrlsSubject = new ReplaySubject<CountryLanguageUrls>(1);
  public readonly countrySettingUrls = this.countrySettingUrlsSubject.asObservable();

  private startPageCountryLanguagesSubject = new ReplaySubject<CountryLanguages>(1);
  public readonly startPageCountryLanguages = this.startPageCountryLanguagesSubject.asObservable();

  private fallbackLanguageSubject = new ReplaySubject<string>(1);
  public readonly fallbackLanguage = this.fallbackLanguageSubject.asObservable();

  private fallbackCountrySubject = new ReplaySubject<string | undefined>(1);
  public readonly fallbackCountry = this.fallbackCountrySubject.asObservable();

  private cookieMoreLinkSubject = new ReplaySubject<string>(1);
  public readonly cookieMoreLink = this.cookieMoreLinkSubject.asObservable();

  private privacyPolicyLinkSubject = new ReplaySubject<string>(1);
  public readonly privacyPolicyLink = this.privacyPolicyLinkSubject.asObservable();

  private divisionSubject = new ReplaySubject<string>(1);
  public readonly division = this.divisionSubject.asObservable();

  private divisionColorSubject = new ReplaySubject<DivisionTitleColor>(1);
  public readonly divisionColor = this.divisionColorSubject.asObservable();

  private navigationSubject = new ReplaySubject<NavigationLevel>(1);
  public readonly navigation = this.navigationSubject.asObservable();

  private navigationCardsSubject = new ReplaySubject<Map<string, NavigationCard>>(1);
  public readonly navigationCards = this.navigationCardsSubject.asObservable();

  private footerSubject = new ReplaySubject<FooterSection>(1);
  public readonly footer = this.footerSubject.asObservable();

  private logoSubject = new ReplaySubject<string>(1);
  public readonly logo = this.logoSubject.asObservable();

  private homeUrlSubject = new ReplaySubject<string>(1);
  public readonly homeUrl = this.homeUrlSubject.asObservable();

  private customerPortalSubject = new ReplaySubject<LinkSection>(1);
  public readonly customerPortal = this.customerPortalSubject.asObservable();

  private titleSuffixSubject = new ReplaySubject<string>(1);
  public readonly titleSuffix = this.titleSuffixSubject.asObservable();

  private siteNameSubject = new ReplaySubject<string>(1);
  public readonly siteName = this.siteNameSubject.asObservable();

  private readonly regionCode$ = new ReplaySubject<string | null>(1);

  private globalSettingsUrl$ = new ReplaySubject<string>(1);

  private readonly localeId = inject(LOCALE_ID);

  private logger = getLogger(Loggers.Renderer);

  private pendingTasks = inject(ExperimentalPendingTasks);

  public setRegionCode(regionCode: string | null): void {
    if (regionCode !== null) {
      regionCode = regionCode.toUpperCase();
    }
    this.regionCode$.next(regionCode);
  }

  public init() {
    // Once the global settings are loaded
    const globalSettings$ = this.fetchGlobalSettings().pipe(share());
    globalSettings$.subscribe({
      next: (globalSettings) => {
        this.updateGlobalSettings(globalSettings);
      },
      error: (error) => {
        if (isPlatformServer(this.platformId) && this.response) {
          this.response.status(500);
          this.response.end();
        }
        throw error;
      },
    });

    // Fetch the country settings as soon as the region and the globalSettings are loaded and update if the region updates
    combineLatest([this.regionCode$.pipe(distinctUntilChanged()), globalSettings$])
      .subscribe({
        next: ([region, globalSettings]) => {
          if (region === null) {
            this.updateCountrySettings(globalSettings.countrySettingUrls[GLOBAL_REGION][this.localeId], this.localeId);
            return;
          }
          if (globalSettings.countrySettingUrls[region] === undefined) {
            this.logger.error(`No country settings found for region ${region}`);
            if (isPlatformServer(this.platformId) && this.response) {
              this.response.status(500);
              this.response.end();
            }
            return;
          }
          this.updateCountrySettings(globalSettings.countrySettingUrls[region][this.localeId], this.localeId, region);
        },
        error: (error) => {
          if (isPlatformServer(this.platformId) && this.response) {
            this.response.status(500);
            this.response.end();
          }
          throw error;
        },
      });
  }

  public setGlobalSettingsUrl(globalSettingsUrl: string): void {
    this.globalSettingsUrl$.next(globalSettingsUrl);
  }

  private fetchGlobalSettings(): Observable<GlobalSettings> {
    if (environment.firstSpirit === true) { // If we are in firstSpirit we need to wait for the page load and read the path from there
      return this.globalSettingsUrl$.pipe(
        mergeMap(globalSettingsUrl => this.contentRepositoryService.fetch<GlobalSettings>(globalSettingsUrl)),
        catchError((error) => {
          this.logger.error('Failed to fetch global settings', {
            error,
          });
          return this.contentRepositoryService.handleError(error, this.localeId);
        }),
      );
    }

    return this.contentRepositoryService.fetch<GlobalSettings>(environment.globalSettingsPath).pipe(
      catchError((error) => {
        this.logger.error('Failed to fetch global settings', {
          error,
        });
        return this.contentRepositoryService.handleError(error, this.localeId);
      }),
    );
  }

  private updateGlobalSettings(globalSettings: GlobalSettings) {
    // Set the favicon

    const faviconElement = this.document.getElementById('favicon');
    if (faviconElement !== null) {
      faviconElement.setAttribute('type', globalSettings.faviconType);
      faviconElement.setAttribute('href', globalSettings.favicon);
    }

    // Update the available languages
    this.availableLanguagesSubject.next(globalSettings.availableLanguages);

    this.countrySettingUrlsSubject.next(globalSettings.countrySettingUrls);

    this.startPageCountryLanguagesSubject.next(globalSettings.startPageCountryLanguageCombination);

    this.fallbackLanguageSubject.next(globalSettings.fallbackLanguage);
    this.fallbackCountrySubject.next(globalSettings.fallbackCountry);

    this.overrideHaupiaTargetSubject.next(globalSettings.overrideHaupiaTarget);
    this.showGlobalSearchSubject.next(globalSettings.showGlobalSearch);

    this.oneTrustDomainScriptSubject.next(globalSettings.oneTrustDomainScript);
    this.mappSettingsSubject.next(globalSettings.mappConfig);

    this.localPartLabelsSubject.next(globalSettings.localPartLabel);
  }

  private transformAsyncNavigationItems(items: AsyncNavigationLevelResponse): NavigationLevel {
    const navigationLevel: NavigationLevel = [];
    for (const [label, href, children] of items) {
      const newItem: NavigationItem = {
        label,
        href,
        target: '_blank',
      };
      if (children) {
        newItem.children = this.transformAsyncNavigationItems(children);
      }
      navigationLevel.push(newItem);
    }
    return navigationLevel;
  }

  private fetchExternalNavigationChildren(url: string): Observable<NavigationLevel> {
    return this.http.get<AsyncNavigationLevelResponse>(url).pipe(
      catchError(() => EMPTY), // TODO: properly handle error, error is logged with http-logger.interceptor
      map((result) => this.transformAsyncNavigationItems(result)),
    );
  }

  private updateCountrySettings(countrySettingsUrl: string, localeId: string, region?: string): void {

    this.contentRepositoryService.fetch<CountrySettings>(countrySettingsUrl).pipe(
      catchError((error) => {
        this.logger.error('Failed to fetch country settings', {
          error,
        });
        return this.contentRepositoryService.handleError(error, localeId, region);
      }),
    ).subscribe((countrySettings) => {
      if ('divisionTitle' in countrySettings) {
        this.divisionSubject.next(countrySettings.divisionTitle);
        this.divisionColorSubject.next(countrySettings.divisionTitleColor);
      }

      if( countrySettings.customThemeUrl ) {
        // <link rel="stylesheet" href="customThemeUrl">
        const styleElement = this.document.createElement('link');
        styleElement.rel = 'stylesheet';
        styleElement.href = countrySettings.customThemeUrl;
        this.document.head.appendChild(styleElement);
      }

      if (countrySettings.customerPortal) {
        this.customerPortalSubject.next(countrySettings.customerPortal);
      }

      this.titleSuffixSubject.next(countrySettings.titleSuffix);
      this.siteNameSubject.next(countrySettings.siteName);
      this.cookieMoreLinkSubject.next(countrySettings.cookieMoreLink);
      this.privacyPolicyLinkSubject.next(countrySettings.privacyPolicyLink);
      this.homeUrlSubject.next(countrySettings.homeUrl);
      this.logoSubject.next(countrySettings.logo);
      this.footerSubject.next(countrySettings.footer);

      const navigationLevel = this.convertNavigationLevel(countrySettings.navigation);
      this.navigationCardsSubject.next(this.extractNavigationCards(countrySettings.navigation));
      this.navigationSubject.next(navigationLevel);

      // Load additional navigation items
      const remoteNavigationChildrenObservables = [];
      for (const navigationItem of navigationLevel) {
        if (navigationItem.externalNavigationChildrenUrl) {
          remoteNavigationChildrenObservables.push(
            this.fetchExternalNavigationChildren(navigationItem.externalNavigationChildrenUrl).pipe(
              tap((navigationChildren) => {
                navigationItem.children = navigationChildren;
              }),
            ),
          );
        }
      }

      if (remoteNavigationChildrenObservables.length > 0) {
        const task = this.pendingTasks.add();
        forkJoin(remoteNavigationChildrenObservables).subscribe(async () => {
          // Wee need to clone the array to trigger change detection
          this.navigationSubject.next([...await firstValueFrom(this.navigationSubject)]);
          task();
        });
      }
    });
  }

  private extractNavigationCards(navigationItems: NavigationItemResponse[], contentIdCounter = [0]): Map<string, NavigationCard> {
    let navigationContent = new Map<string, NavigationCard>();

    navigationItems
      .map(navigationItem => navigationItem.content)
      .filter((content): content is NavigationListItemSection => content !== undefined)
      .forEach((content) => {
        navigationContent.set(this.getContentId(contentIdCounter[0]++), {
          heading: content.attributes.title,
          content: content.attributes.text,
          picture: imageToPicture(content.attributes.image.attributes, AspectRatio.Standard),
          link: content.attributes.link ? {
            href: content.attributes.link.attributes.url,
            target: content.attributes.link.attributes.target,
          } : undefined,
        })
      });

    for (const navigationItem of navigationItems) {
      if (navigationItem.children === undefined) {
        continue;
      }

      navigationContent = new Map([
        ...navigationContent.entries(),
        ...this.extractNavigationCards(navigationItem.children, contentIdCounter).entries(),
      ]);
    }

    return navigationContent;
  }

  private convertNavigationLevel(navigationItems: NavigationItemResponse[], contentIdCounter = [0]): (NavigationItem & { externalNavigationChildrenUrl?: string })[] {
    return navigationItems.map(navigationItem => (
      this.convertNavigationItem(navigationItem, contentIdCounter)
    ));
  }
  private convertNavigationItem(navigationItem: NavigationItemResponse, contentIdCounter: number[]): NavigationItem & { externalNavigationChildrenUrl?: string } {
    return {
      label: navigationItem.label,
      href: navigationItem.url,
      target: navigationItem.isExternal ? '_blank' : undefined,
      children: navigationItem.children ? this.convertNavigationLevel(navigationItem.children, contentIdCounter) : undefined,
      externalNavigationChildrenUrl: navigationItem.childrenJsonUrl,
      contentId: navigationItem.content ? this.getContentId(contentIdCounter[0]++) : undefined,
    };
  }

  private getContentId(counter: number): string {
    return `navigation-content-${counter}`;
  }

  public ngOnDestroy() {
    this.navigationSubject.complete();
    this.cookieMoreLinkSubject.complete();
    this.footerSubject.complete();
    this.homeUrlSubject.complete();
    this.logoSubject.complete();
    this.divisionSubject.complete();
    this.customerPortalSubject.complete();
    this.oneTrustDomainScriptSubject.complete();
    this.localPartLabelsSubject.complete();
    this.mappSettingsSubject.complete();
    this.titleSuffixSubject.complete();
    this.siteNameSubject.complete();
    this.navigationCardsSubject.complete();
    this.divisionColorSubject.complete();
    this.privacyPolicyLinkSubject.complete();
    this.fallbackCountrySubject.complete();
    this.fallbackLanguageSubject.complete();
    this.startPageCountryLanguagesSubject.complete();
    this.countrySettingUrlsSubject.complete();
    this.availableLanguagesSubject.complete();
    this.showGlobalSearchSubject.complete();
    this.overrideHaupiaTargetSubject.complete();
  }

}
