import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { LocaleService } from './locale.service';
import { GLOBAL_REGION } from './settings-constants';


export const localeRedirectGuard: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree> => {
  const locale = inject(LocaleService);
  const router = inject(Router);

  return locale.optimalStartPageLocale.pipe(
    first(),
    map((locale) => {
      if (!locale.region || locale.region === GLOBAL_REGION) {
        return [locale.language];
      } else {
        return [locale.language, locale.region.toLowerCase()];
      }
    }),
    map((commands) => {
      const command = [...commands, ...route.url.map(part => part.path)];
      const url = new URL(state.url, 'https://fakebase');
      return router.createUrlTree(command, {
        fragment: url.hash ? url.hash.substring(1) : undefined,
        queryParams: url.search ? Object.fromEntries(url.searchParams.entries()) : null,
      });
    }),
  );
}
